import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './App.css';
import Login from './Login';
import SearchEngine from './SearchEngine';
import Terms from './Terms';

function App() {
  return (
    <Router>
        <Helmet>
          <title>Safe City Parking</title>
        </Helmet>
      <div className="App">
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/search-engine" element={<SearchEngine />} />
          <Route path="/terms" element={<Terms />} /> 
        </Routes>
      </div>
    </Router>
  );
}

export default App;
