import React, { useState } from 'react';
import logo from './assets/logo-final.png';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import countryList from 'react-select-country-list';


const Login = () => {
    const [licensePlate, setLicensePlate] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [country, setCountry] = useState(null);
    const [termsChecked, setTermsChecked] = useState(false);
    const navigate = useNavigate();

    const options = countryList().getData().map(country => ({
        ...country,
        label: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={`https://flagcdn.com/w20/${country.value.toLowerCase()}.png`}
              alt={country.label}
              style={{ marginRight: 10 }}
            />
            {country.label}
          </div>
        ),
      }));

    const handleCountryChange = (selectedOption) => {
        setCountry(selectedOption);
      };

    const checkData = () => {
      // Add your validation logic here
      // if (!iti.isValidNumber()) {
      //   alert('Please enter a valid mobile number');
      //   return false;
      // }
      return true;
    };
  
    const handleSubmit = (e) => {
        e.preventDefault();
        if (termsChecked && checkData()) {
            navigate('/search-engine');
        } else {
            alert('You must agree to the terms and conditions to continue');
        }
    };

    const isFormValid = termsChecked && (licensePlate || (country && mobileNumber));


    return (
      <div style={{display:'flex', flexDirection:'column', justifyContent: 'space-between', alignItems:'center', width:'100%', minHeight: '100vh', overflow:'hidden'}}>
        <div style={{ backgroundColor: '#38ADEF', padding: '20px', width: '100%', textAlign: 'center' }}>
          <img  src={logo} alt="Safe City Parking" width="300" />
        </div>
        
        <form id="frm" action="/check-user-informtion" method="post" name="frm" onSubmit={handleSubmit} style={{paddingLeft: '10px', paddingRight: '10px'}}>
          <div style={{ fontFamily: 'Outfit', textAlign: 'center', backgroundColor: '#fafafa', border: '1px #afafaf solid', padding: '20px', borderRadius: '8px' }}>
            <div>
              <div style={{fontWeight: '700', marginBottom: '20px', width: '100%'}}>To search your vehicle enter the following details</div>
              
              <div>
                <input id="Language" name="Language" type="hidden" value="en" />
                <input id="MobileNumberHidden" name="MobileNumber" type="hidden" value="" />
                <div style={{display: 'flex', flexDirection: 'column', marginBottom: '20px', width: '100%'}}>
                    <div style={{marginBottom: '10px'}}><label htmlFor="LicensePlate">Rental car license plate</label></div>
                    <input
                        className="textBox"
                        data-val="true"
                        data-val-required="Please enter your license plate number"
                        id="LicensePlate"
                        limit="8"
                        name="LicensePlate"
                        type="text"
                        value={licensePlate}
                        onChange={(e) => setLicensePlate(e.target.value)}
                        style={{width: '40%', marginLeft: 'auto', marginRight: 'auto', borderRadius: '5px'}}
                    />
                    <span className="errorlabel"><span className="field-validation-valid" data-valmsg-for="LicensePlate" data-valmsg-replace="true"></span></span>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px', width: '100%'}}>
                    <div style={{marginBottom: '10px'}}><label htmlFor="CountryCode">Country Code</label></div>
                    <Select
                    options={options}
                    value={country}
                    onChange={handleCountryChange}
                    styles={{
                        container: (provided) => ({
                        ...provided,
                        width: '40%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        }),
                    }}
                    />
                </div>
  
                <div  style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px', width: '100%'}}>
                  <div style={{marginBottom: '10px'}}><label htmlFor="MobileNumber">Mobile Number</label></div>
                  <input
                    className="textBox"
                    id="MobileNumber"
                    name="MobileNumber"
                    type="text"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    style={{width: '40%', marginLeft: 'auto', marginRight: 'auto', borderRadius: '5px'}}
                  />
                  <span className="errorlabel"><span className="field-validation-valid" data-valmsg-for="MobileNumber" data-valmsg-replace="true"></span></span>
                </div>
  
                <div style={{marginBottom: '20px'}}>
                  <input
                    className="checkbox-box"
                    data-val="true"
                    data-val-range="You must agree to the terms and conditions to continue"
                    data-val-range-max="True"
                    data-val-range-min="True"
                    data-val-required="The I agree to the terms and conditions field is required."
                    id="TermsAndConditions"
                    name="TermsAndConditions"
                    type="checkbox"
                    value="true"
                    checked={termsChecked}
                    onChange={(e) => setTermsChecked(e.target.checked)}
                  />
                  <input name="TermsAndConditions" type="hidden" value="false" />
                  <a href="/terms">I agree to the terms and conditions</a>
                  <span className="errorlabel"><span className="field-validation-valid" data-valmsg-for="TermsAndConditions" data-valmsg-replace="true"></span></span>
                </div>
                <button 
                    type="submit" id="addSubscription" 
                    style={{ fontSize: '18px', padding: '10px', borderRadius: '5px', backgroundColor: '#38ADEF', fontFamily :'Outfit' }} 
                    disabled={!isFormValid}
                >
                    Login
                </button>
              </div>
              <br />
              <p></p>
            </div>
          </div>
        </form>
  
        <div style={{ backgroundColor: '#38ADEF', padding: '20px', width: '100%', textAlign: 'center' }}>
          <span style={{ fontSize: '14px', color: 'white', fontWeight: 'bold' }}>
            Contact us for support or refund of the towing fee:&nbsp;&nbsp;
            <a href="mailto:office@safecityparking.com" className="whitelink">office@safecityparking.com</a>
          </span>
        </div>
      </div>
    );
  };

export default Login;