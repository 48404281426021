import React from 'react';

function Terms() {
  return (
    <div>
      <h4>Service Policy of City Towing Protection</h4>
        <ol style={{width: "60%", display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto', textAlign: 'left'}}>
            <li>The customer (hereinafter the "Renter" or “Car Renter") grants power of attorney to the Rental company (hereinafter the "Company") to use this service through third-party services (hereinafter the “the Safe City Parking Company Ltd." or the “Third Party"). The Third Party is providing the location services through a dedicated website, and a Towing refund, in case the rental car is towed away from a prohibited parking (hereinafter the "Service").</li>
            <li>The Renter grants the Company power of attorney to claim from the Third Party a refund for coverage of towing from a prohibited parking.</li>
            <li>In the event of towing from a prohibited parking, except if the vehicle was parked in an official disabled parking, the Renter will pay for the tow to the local authority as legally required, only then he will be entitled to request a refund from the Company or from the Third Party up to a maximum $150, per tow.</li>
            <li>The Service covers the cost of one (1) tow per month only (30 calendar days).</li>
            <li>The Service covers the cost of the tow only (municipal or police fines are not covered by this Service).</li>
            <li>The Company will refund the tow amount paid by the Renter, directly to his account within 14 days of presenting the demand to cover the payment, which the Renter will submit to the Company by email (office@safecityparking.com) or by contacting one of the representatives of the Company in its branches. The refund is conditioned upon presenting of towing documents and confirmation of the towing payment and presenting proof of the payment of the parking fine, which is related to the towing.</li>
            <li>The towing coverage is nationwide and international no matter if the location of the towing is listed in the website or not, provided that at the time of the rental, the Renter has paid for the Service the amount required by the Company in exchange for this coverage.</li>
            <li>By registering for the Service, the Renter authorizes the Company or the Third Party to send him an SMS message or an email, with a web link to the Service.</li>
        </ol>
    </div>
  );
}

export default Terms;